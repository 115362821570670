<template lang="pug">
q-dialog(
  class="no-scroll" 
  ref="roomDialog" 
  transition-hide="slide-down"
  v-on:before-hide="beforeHide"
)
  .bg-white.no-scroll(style="width:400px")
    q-toolbar
      .text-capitalize Join room
      q-space 
      q-btn(dense flat icon="close" v-close-popup )      
    q-separator

    .q-pa-md.bg-blue-grey-1
      div Enter ID for room to join
      q-input(v-model="roomId" label="room ID" outlined dense bg-color="white")
    
    q-separator
    q-toolbar
      q-space
      q-btn.q-mx-sm(label="Cancel" @click="onCancelJoinRoom")
      q-btn.q-mx-sm(label="Join" @click="joinRoom")
</template>
<script>
export default {
  data(){
    return {
      roomId: null
    }
  },
  emits:['ok', 'cancel'],
  methods:{
    beforeHide: function(){
      if(!(this.$refs['roomDialog'] instanceof Object))return
      this.roomId = null
      this.$refs['roomDialog'].hide()
    },
    onCancelJoinRoom: function(){
      this.$emit('cancel')
      this.beforeHide()
    },
    joinRoom : function(){
      console.log('dialog',this.roomId)
      this.$emit('ok', this.roomId)
      this.beforeHide()
    },
  }
}
</script>