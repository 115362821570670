<template lang="pug">

q-layout(view="hhh LpR fff")
  //- Layout
  Header

  //- Body
  q-page-container
    q-page
      Video

</template>

<style>
</style>

<script>
import Header from "./_comps/Header.vue"
import Video from "./_comps/Video.vue"

export default {
  components: {
    Header,
    Video
  },
}
</script>
