<template lang="pug">
.q-pa-md.q-px-lg

  .q-mb-lggit.text-grey-8
    div SDP 페이지를 변형하여 Video chat demo를 만들었습니다. 기능은 다음과 같습니다.
    .q-pl-md - Video chat (real local - remote)
    .q-pl-md - Restart ICE

  //- section1
  .row.q-mb-lg
    q-item.q-pl-none
      q-item-section(side) Audio source:
      q-item-section
        q-select(
          v-model="audioSource" 
          :options="audioSurceOptions" 
          outlined dense bg-color="white"
          style="min-width:200px"
        )
    q-item
      q-item-section(side) Video source:
      q-item-section
        q-select(
          v-model="videoSource" 
          :options="videoSourceOptions" 
          outlined dense bg-color="white"
          style="min-width:200px"
        )  

  //- section2
  .q-mb-lg.q-gutter-md
    q-btn(
      label="Get Media" 
      :color="isDisabledGetMediaBtn ? 'white' :'primary'" 
      :text-color="isDisabledGetMediaBtn ? 'black' : 'white'" 
      style="width:230px" 
      :disable="isDisabledGetMediaBtn"
      @click="onClickGetMedia"
    )
    q-btn(
      label="Create Peer Connection" 
      :color="isDisabledCreatePCBtn ? 'white' : 'primary'" 
      :text-color="isDisabledCreatePCBtn ? 'black' : 'white'"
      style="width:230px"
      :disable="isDisabledCreatePCBtn"
      @click="onClickCreateRoom"
    )
    q-btn(
      label="Create Offer" 
      :color="isDisabledCreateOfferBtn ? 'white' : 'primary'" 
      :text-color="isDisabledCreateOfferBtn ? 'black' : 'white'"
      style="width:230px"
      :disable="isDisabledCreateOfferBtn"
      @click="onClickCreateOffer"
    )
    q-btn(
      label="Set Offer" 
      :color="isDisabledSetOfferBtn ? 'white' : 'primary'" 
      :text-color="isDisabledSetOfferBtn ? 'black' : 'white'"
      style="width:230px"
      :disable="isDisabledSetOfferBtn"
      @click="onClickSetOffer"
    )
    q-btn(
      label="Join Room" 
      :color="isDisabledJoinRoom ? 'white' : 'primary'" 
      :text-color="isDisabledJoinRoom ? 'black' : 'white'"
      style="width:230px"
      :disable="isDisabledJoinRoom"
      @click="onClickJoinRoom"
    )
    q-btn(
      label="Create Answer" 
      :color="isDisabledCreateAnswerBtn ? 'white' : 'primary'" 
      :text-color="isDisabledCreateAnswerBtn ? 'black' : 'white'"
      style="width:230px"
      :disable="isDisabledCreateAnswerBtn"
      @click="onClickCreateAnswer"
    )
    q-btn(
      label="Set Answer" 
      :color="isDisabledSetAnswerBtn ? 'white' : 'primary'" 
      :text-color="isDisabledSetAnswerBtn ? 'black' : 'white'" 
      style="width:230px"
      :disable="isDisabledSetAnswerBtn"
      @click="onClickSetAnswer"
    )
    q-btn(
      label="Hang up" 
      :color="isDisabledHangUpBtn ? 'white' : 'primary'" 
      :text-color="isDisabledHangUpBtn ? 'black' : 'white'" 
      style="width:230px"
      :disable="isDisabledHangUpBtn"
      @click="onClickHangUp"
    )
  q-separator

  //- section3
  div {{ roomMsg }}
  .row
    .col.q-pa-md
      .text-h5.q-my-md Local
      video.full-width(ref="localVideo" playsinline autoplay muted style="border: 1px solid black")
      .text-h5.q-my-md Local SDP
      q-scroll-area(style="height: 200px") 
        q-input(
          type="textarea" 
          v-model="localSDP" 
          readonly outlined bg-color="white"
        )
    .col.q-pa-md
      .text-h5.q-my-md Remote
      video.full-width(ref="remoteVideo" playsinline autoplay muted style="border: 1px solid black")
      .text-h5.q-my-md Remote SDP
      q-scroll-area(style="height: 200px") 
        q-input(
          type="textarea" 
          v-model="remoteSDP" 
          readonly outlined bg-color="white"
        )

  JoinRoom(v-model="openDialog" @ok="joinRoom" @cancel="onClickCancelJoinRoom")

</template>
<script>
import JoinRoom from './JoinRoom.vue'
export default {
  components: {JoinRoom},
  data(){
    return {
      // page data
      audioSource: '',
      videoSource: '',
      audioSurceOptions: [],
      videoSourceOptions: [],
      localSDP: '',
      remoteSDP: '',

      // disabled
      isDisabledGetMediaBtn: false,
      isDisabledCreatePCBtn: true,
      isDisabledCreateOfferBtn: true,
      isDisabledSetOfferBtn: true,
      isDisabledJoinRoom: true,
      isDisabledCreateAnswerBtn: true,
      isDisabledSetAnswerBtn: true,
      isDisabledHangUpBtn: true,

      // pc data
      pc: null,
      servers : {
        iceServers: [
          {
            urls: [
              'stun:stun1.l.google.com:19302',
              'stun:stun2.l.google.com:19302',
              'stun:stun.nfon.net:3478',
              'stun:stun.nonoh.net:3478'
            ],
          },
        ],
        iceCandidatePoolSize: 10
      }, 
      localVideo: null,
      remoteVideo: null,
      localStream: null,
      remoteStream: null,

      roomId: null,
      roomMsg: '',
      openDialog: false,
    }
  },
  mounted : async function(){
    this.initMediaSource()
    this.localVideo = this.$refs['localVideo']
    this.remoteVideo = this.$refs['remoteVideo']
  },
  methods:{
    ////////////////////////
    // PRE : get media source
    initMediaSource: function(){
      try {
        navigator.mediaDevices.enumerateDevices().then(this.gotSources)
      } catch (e) {
        console.log('initMediaSource err :>>', e)
      }
    },
    gotSources(sourceInfos) {
      let audioCount = 0
      let videoCount = 0

      for(let i = 0 ; i < sourceInfos.length; i++){
        let data = {
          value: sourceInfos[i].deviceId,
          label: sourceInfos[i].label
        }
        switch(sourceInfos[i].kind){
          case 'audioinput' :
            audioCount++
            if(data.label === '') data.label = `Audio ${audioCount}`
            this.audioSurceOptions.push(data)
            this.audioSource = this.audioSurceOptions[0]
            break
          case 'videoinput' :
            videoCount++
            if(data.label === '') data.label = `Video ${videoCount}`
            this.videoSourceOptions.push(data)
            this.videoSource = this.videoSourceOptions[0]
            break
          default : 
            console.log('unknown', JSON.stringify(sourceInfos[i]));
        }
      }
    },

    //////////////////////////////
    // STEP 1. GET MEDIA
    onClickGetMedia: async function(){
      this.isDisabledGetMediaBtn = true

      if(this.localStream){
        this.localVideo.srcObject = null
        this.localStream.getTracks().forEach(track => track.stop())
      }
      const audioSource = this.audioSource
      const videoSource = this.videoSource

      const constraints = {
        audio: {
          optional: [{ sourceId: audioSource }]
        },
        video: {
          optional: [{ sourceId: videoSource }]
        }
      }
      console.log('Requested local stream')
      try {
        const userMedia = await navigator.mediaDevices.getUserMedia(constraints)
        this.gotStream(userMedia)
      } catch (e) {
        console.log('navigator.getUserMedia error: ', e)
      }

      this.isDisabledJoinRoom = false
    },
    gotStream: function (stream) {
      console.log('Received local stream')
      this.localVideo.srcObject = stream
      this.localStream = stream
      this.remoteStream = new MediaStream()
      this.remoteVideo.srcObject = this.remoteStream
      this.isDisabledCreatePCBtn = false
    },

    //////////////////////////////
    // SETP 2. CREATE Room
    onClickCreateRoom: async function(){
      this.isDisabledCreatePCBtn = true
      this.isDisabledJoinRoom = true
      this.isDisabledCreateOfferBtn = false
      console.log('Starting call')

      // 1) Logs
      const videoTracks = this.localStream.getVideoTracks()
      const audioTracks = this.localStream.getAudioTracks()
      if(videoTracks.length > 0 ) console.log(`Using video device: ${videoTracks[0].label}`)
      if(audioTracks.length > 0) console.log(`Using audio device: ${audioTracks[0].label}`)

      // 2) Create local PC
      this.pc = new RTCPeerConnection(this.servers)
      this.checkingPCLog()
      console.log('Created local peer connection object pc')

      // 3) Ice candidate
      this.pc.onicecandidate = e =>  this.onIceCandidate(this.pc, e, 'offer')
      this.pc.oniceconnectionstatechange = e => this.onIceStateChange(this.pc, e, 'offer')
      this.pc.ontrack = this.gotRemoteStream
      this.localStream.getTracks().forEach(track => this.pc.addTrack(track, this.localStream))
      console.log('Adding Offer Stream to peer connection')
    },
    /////////////////////////////////
    // STEP 3. Create Offer
    onClickCreateOffer : async function () {
      try {
        const offer = await this.pc.createOffer()
        this.gotOfferDescription(offer)
      } catch (err) {
        this.onCreateSessionDescriptionError(err)
      }
    },
    gotOfferDescription : function (description) {
      this.localSDP = description.sdp
      // this.isDisabledCreateOfferBtn = true
      this.isDisabledSetOfferBtn = false
    },

    /////////////////////////////////
    // STEP 4. Set Offer
    onClickSetOffer : async function(){
      const sdp = this.localSDP
        .split('\n')
        .map(v => v.trim())
        .join('\r\n')
      const offer = {
        type: 'offer',
        sdp: sdp
      }

      // set offer firebase
      const callRef = await this.$db.collection('calls').doc()
      await callRef.set({offer})
      this.roomId = callRef.id
      this.roomMsg = `Current room is ${this.roomId} - You are the caller!`

      // set local
      try {
        await this.pc.setLocalDescription(offer)
        this.onSetSessionDescriptionSuccess()
      } catch (err) {
        this.onSetSessionDescriptionError(err)
        return
      }

      // 4) setRemoteDescription : Answer
      callRef.onSnapshot(async snapshot => {
        const data = snapshot.data()
        if (!this.pc.currentRemoteDescription && data && data.answer) {
          console.log('Got remote description: ', data.answer)
          const rtcSessionDescription = new RTCSessionDescription(data.answer)
          await this.pc.setRemoteDescription(rtcSessionDescription)
          this.remoteSDP = data.answer.sdp
        }
      })

      // 5) ADD remote ICE candidate
      callRef.collection('calleeCandidates').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            let data = change.doc.data()
            console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
            await this.pc.addIceCandidate(new RTCIceCandidate(data))
          }
        })
      })

      this.isDisabledHangUpBtn = false
    },

    /////////////////////////////////
    // STEP 5. Join Room 
    joinRoom: async function(roomId){
      this.isDisabledJoinRoom = true
      this.isDisabledCreatePCBtn = true
      this.isDisabledCreateAnswerBtn = false
      console.log('Starting call')
      this.roomId = roomId
      console.log('joinRoom :>>',this.roomId, roomId)

      // 0) Check
      const callRef = this.$db.collection('calls').doc(`${this.roomId}`)
      const callSnapshot = await callRef.get()
      console.log('Got room:', callSnapshot.exists, callSnapshot.data().offer)
      if(!callSnapshot.exists) return

      // 1) Logs
      const videoTracks = this.localStream.getVideoTracks()
      const audioTracks = this.localStream.getAudioTracks()
      if(videoTracks.length > 0 ) console.log(`Using video device: ${videoTracks[0].label}`)
      if(audioTracks.length > 0) console.log(`Using audio device: ${audioTracks[0].label}`)

      // 2) remote PC
      this.pc = new RTCPeerConnection(this.servers)
      this.checkingPCLog()
      console.log('Created remote peer connection object pc')

      // 3) Ice Candidate
      this.pc.onicecandidate = e => this.onIceCandidate(this.pc, e, 'answer')
      this.pc.oniceconnectionstatechange = e => this.onIceStateChange(this.pc, e, 'answer')
      this.pc.ontrack = this.gotRemoteStream
      this.localStream.getTracks().forEach(track => this.pc.addTrack(track, this.localStream))
      console.log('Adding remote Stream to peer connection')

      // 4) Got Offer
      const offer = callSnapshot.data().offer
      console.log('Got offer:', offer)
      await this.pc.setRemoteDescription(new RTCSessionDescription(offer))
      this.remoteSDP = offer.sdp
    },

    /////////////////////////////////
    // STEP 5. Create Answer
    onClickCreateAnswer: async function(){    
      try {
        const answer = await this.pc.createAnswer()
        this.gotAnswerDescription(answer)
      } catch (err) {
        this.onCreateSessionDescriptionError(err)
      }
    },
    gotAnswerDescription : function(description){
      this.localSDP = description.sdp
      this.isDisabledCreateAnswerBtn = true
      this.isDisabledSetAnswerBtn = false
    },


    /////////////////////////////////
    // STEP 6. Set Answer
    onClickSetAnswer : async function () {
      const sdp = this.localSDP
        .split('\n')
        .map(v => v.trim())
        .join('\r\n')
      const answer = {
        type: 'answer',
        sdp: sdp
      }
      await this.$db.collection('calls').doc(`${this.roomId}`).update({answer})

      // set local(answer) 
      try{
        await this.pc.setLocalDescription(answer)
        this.onSetSessionDescriptionSuccess()
      } catch (err) {
        this.onSetSessionDescriptionError(err)
        return
      }

      // Add remote ICE candidate : Offer
      const callRef = this.$db.collection('calls').doc(`${this.roomId}`)
      callRef.collection('callerCandidates').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
            await this.pc.addIceCandidate(new RTCIceCandidate(data))
          }
        })
      })

      this.isDisabledHangUpBtn = false
      this.isDisabledCreateAnswerBtn = false
    },


    /////////////////////////////////
    // STEP 7. Hang UP
    onClickHangUp: async function(){
      console.log('Ending call')
      this.localStream.getTracks().forEach(track => track.stop())
      this.remoteStream.getTracks().forEach(track => track.stop())
      this.pc.close()
      this.pc = null

      this.localSDP = ''
      this.remoteSDP = ''

      if (this.roomId) {
        const callRef = this.$db.collection('calls').doc(this.roomId);
        const calleeCandidates = await callRef.collection('calleeCandidates').get()
        calleeCandidates.forEach(async candidate => await candidate.ref.delete())
        const callerCandidates = await callRef.collection('callerCandidates').get()
        callerCandidates.forEach(async candidate => await candidate.ref.delete())
        await callRef.delete()
      }

      this.isDisabledGetMediaBtn = false
      this.isDisabledCreatePCBtn = true
      this.isDisabledCreateOfferBtn = true
      this.isDisabledSetOfferBtn = true
      this.isDisabledCreateAnswerBtn = true
      this.isDisabledSetAnswerBtn = true
      this.isDisabledHangUpBtn = true
    },

    /////////////////////////////////
    // Dialog
    onClickJoinRoom: function(){
      this.openDialog = true
    },
    onClickCancelJoinRoom : function(){
      console.log('cancle join')
      // this.isDisableCreateCallBtn = false
      // this.isDisableJoinRoomBtn = false
    },

    /////////////////////////////////
    // ICE tools
    gotRemoteStream: function(e){
      if (this.remoteVideo.srcObject !== e.streams[0]) {
        this.remoteVideo.srcObject = e.streams[0]
        console.log('Received remote stream')
      }
    },

    onIceCandidate: async function(pc, event, type){
      try {
        if(!event.candidate){
          console.log('Got candidate')
          return
        }
        console.log('ICE candidate :>>', event.candidate)
        // await this.pc.addIceCandidate(event.candidate)
        // this.onAddIceCandidateSuccess(pc)
        if(this.roomId){
          const callRef = await this.$db.collection('calls').doc(`${this.roomId}`)
          type === 'offer'
            ? callRef.collection('callerCandidates').add(event.candidate.toJSON())
            : callRef.collection('calleeCandidates').add(event.candidate.toJSON())
        }
      } catch (e) {
        this.onAddIceCandidateError(e)
      }
    },

    onIceStateChange : function(pc, event, type){
      if(!pc) return
      console.log('ICE state change event: ', event);
      console.log("🚀 ~ connection state", pc.connectionState)
      console.log("🚀 ~ ice connection state", pc.iceConnectionState)
      console.log("🚀 ~ ice gathering state", pc.iceGatheringState)
      console.log("🚀 ~ signaling state", pc.signalingState)
      
      if(pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'disconnected'){
        console.log("🚀🚀🚀🚀 ICE connection fail 🚀🚀🚀🚀")
        type === 'offer' ? this.onIceRestartOffer() : this.onIceRestartAnswer()
      }
    },

    onIceRestartOffer: async function(){
      const offerSDP = await this.pc.createOffer({iceRestart: true})
      this.gotOfferDescription(offerSDP)
      await this.pc.setLocalDescription(offerSDP)
      console.log('🚀🚀🚀Restart ICE OFFER')

      // firebase에 저장
      const offer = {
        type: 'offer',
        sdp: JSON.stringify(offerSDP)
      }
      console.log('offerSDP :>>', JSON.stringify(offerSDP))
      const callRef = await this.$db.collection('calls').doc(`${this.roomId}`)
      await this.$db.collection('calls').doc(`${this.roomId}`).set({offer})

      // setRemoteDescription : Answer
      callRef.onSnapshot(async snapshot => {
        const data = snapshot.data()
        if (!this.pc.currentRemoteDescription && data && data.answer) {
          console.log('Got remote description: ', data.answer)
          const rtcSessionDescription = new RTCSessionDescription(data.answer)
          await this.pc.setRemoteDescription(rtcSessionDescription)
          this.remoteSDP = data.answer.sdp
        }
      })

      // ADD remote ICE candidate
      callRef.collection('calleeCandidates').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            let data = change.doc.data()
            console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
            await this.pc.addIceCandidate(new RTCIceCandidate(data))
          }
        })
      })
    },
    onIceRestartAnswer : async function(){
      // offer의 remote 측에 offer 전달하기
      const callRef = this.$db.collection('calls').doc(`${this.roomId}`)
      const callSnapshot = await callRef.get()
      const offer = callSnapshot.data().offer
      console.log('🚀🚀🚀RESTRT ICE Got offer:', offer)

      await this.pc.setRemoteDescription(new RTCSessionDescription(offer))
      this.remoteSDP = offer.sdp

      // offer의 remote 측에서 answer 생성 및 local 저장
      const answerSDP = await this.onClickCreateAnswer()
      const answer = {
        type: 'answer',
        sdp: JSON.stringify(answerSDP)
      }
      await this.$db.collection('calls').doc(`${this.roomId}`).set({answer})
      await this.pc.setLocalDescription(answer)

      // Add remote ICE candidate : Offer
      callRef.collection('callerCandidates').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
            await this.pc.addIceCandidate(new RTCIceCandidate(data))
          }
        })
      })
    },

    // Logs
    onAddIceCandidateSuccess : function () {
      console.log('AddIceCandidate success.')
    },
    onAddIceCandidateError : function (error) {
      console.log(`Failed to add Ice Candidate: ${error.toString()}, ${error}`)
    },

    //////////////////////////////////
    // Description Log tools
    onCreateSessionDescriptionError : function (error) {
      console.log(`Failed to create session description: ${error.toString()}`)
    },
    onSetSessionDescriptionSuccess : function () {
      console.log('Set session description success.');
    },
    onSetSessionDescriptionError : function (error) {
      console.log(`Failed to set session description: ${error.toString()}`);
    },
    checkingPCLog: function() {
      this.pc.oniceconnectionstatechange = () => { // 이것을 이용해서 fail 상태를 감지할 수 있음 -> restart
        console.log("🚀 ~ ICE gathering", this.pc.iceGatheringState)
        console.log("🚀 ~ ICE connection", this.pc.iceConnectionState)
      }
      this.pc.onconnectionstatechange = () => {
        console.log("🚀 ~ Connection", this.pc.connectionState)
      }
    },
  },
}
</script>